import React from 'react'
import Layout from '../../../../components/layout'
import Seo from '../../../../components/seo'

import Illustration from '../../../../svg/landing-page-main.svg'
import OurApproach from '../../../../components/landing/our-approach'
import LandingMainBanner from '../../../../components/landing/main-banner';
import ProblemOfSite from '../../../../components/landing/problem'
import Prices from '../../../../components/landing/prices'
import AboutSection from '../../../../components/page-parts/about-section';
import FormSection from '../../../../components/page-parts/form-section';
import Technologies from '../../../../components/page-parts/technologies'
import SitePageDescription from '../../../../components/landing/description'

const InternetShopPage = () => {

  const content = {
    ttlBlue: 'создание ',
    title: <span>интернет-магазина</span>,
    subtitle: 'Интернет-магазин — неотъемлемая часть любого бизнеса, имеющего каталог товаров или продукции. Он позволяет клиентам совершать покупки, не выходя из дома или работы.',
    buttonText: 'подробнее',
    buttonLink: '#description-block',
  }

  const pageTitle = 'Создание интернет-магазина'
  const pageDescription = 'Сделаем полноценный интернет-магазин! Проанализируем бизнес, аудиторию, конкурентов. Разработаем продающий, современный дизайн. Подключим CMS, CRM и онлайн-оплату. Настроим рекламу в Яндекс Директ и Google Ads. Закажите разработку своего сайта в веб-студии Ascent.'

  // const keywords = ''

  const aboutSite = {
    title: 'Разработка Интернет-магазина «под ключ»',
    description: 
    <>
    <p>В наше время <strong>интернет-магазин</strong> стал неотъемлемой частью жизни практически каждого человека. Это - самый быстрый и доступный способ показать покупателям ассортимент ваших товаров.</p>
    <p>Мы разрабатываем доступные, удобные и функциональные <strong>интернет-магазины</strong>. Вы с легкостью сможете самостоятельно добавлять на сайт товары, отслеживать заказы и анализировать маркетинговые показатели сайта.</p>
    <p>Наша команда поможет вам разработать современный, высококонверсионный интернет-магазин с запоминающимся дизайном. При необходимости настроим рекламу и подключим CRM-систему.</p>
    </>,
    advantagesTitle: 'Преимущества интернет-магазина',
    advantages: [
      'Функционален и информативен',
      'Продажа товаров "онлайн"',
      'Экономия времени покупателей',
      'Свобода выбора товаров',
      'Полная информация о товаре',
      'Уменьшает конечную стоимость товара',
    ],
  }

  const tariffs = {
    base: 'от 120 000 ₽',
    business: 'от 150 000 ₽',
    premium: 'от 170 000 ₽',
  }

  const siteType = "интернет-магазина"

  const stages = [
    {
      name: 'Аналитика',
      desc: 'На подготовительном этапе разработки мы проводим анализ проектов конкурентов, их слабые и сильные места. Анализируем вашу целевую аудиторию, их боли и потребности. На основании собранной информации, предлагаем различные пути решения. ',
      id: 't1',
    },
    {
      name: 'Прототипирование',
      desc: 'Проектируем наглядную структуру сайта. Описываем поведение каждого элемента на странице.  Расставляем триггеры. Пишем продающие тексты.',
      id: 't2',
    },
    {
      name: 'Верстка и программирование',
      desc: 'Верстаем утвержденный вами дизай и структуру сайта. Адаптируем под все устройства. Добавляем анимацию и интерактивность элементам на странице. Добавляем сайту функциональность для удобного взаимодействия с ним.',
      id: 't3',
    },
    {
      name: 'Настройка сервисов аналитики',
      desc: 'Устанавливаем на сайт сервисы аналитики (Яндекс.Метрика, Google Analytics). Настраиваем цели для отслеживания показателей конверсии и результативности сайта. Сбор аналитики для улучшения KPI.',
      id: 't4',
    },
    {
      name: 'Запуск в продакшн',
      desc: 'Проводим полное тестирование сайта. Выявляем на ошибки и проблемы. После успешной проверки запускаем сайт в онлайн. Добавляем сайт в Яндекс.Вебмастер и Google Search Console для видимости его поисковиками.',
      id: 't5',
    },
    {
      name: 'Поддержка',
      desc: 'Мы за долгосрочные и честные отношения! После сдачи проекта, мы предлагаем вам заключить договор для дальнейшего сотрудничества, на протяжении которого мы будем продолжать работать над улучшением показателей вашего сайта, внедрять новые «фичи», тестировать различные гипотезы.',
      id: 't6',
    },
  ]

  return (
    <Layout fixedHeader={true}>
      <Seo title={pageTitle} description={pageDescription} />
      <LandingMainBanner svg={<Illustration />} content={content} />
      <SitePageDescription content={aboutSite} />
      <Prices tariffs={tariffs} siteType={siteType} />
      <ProblemOfSite />
      <OurApproach />
      <Technologies title="этапы разработки сайта" content={stages} mb={10}/>
      <AboutSection />
      <FormSection />
    </Layout>
  )
}

export default InternetShopPage
